import React from 'react';
import { Hidden } from '@material-ui/core';
import ImgLeft from 'src/images/campaign/2022AAA_202211/left.png';
import ImgRight from 'src/images/campaign/2022AAA_202211/right.png';
import ImgRightSp from 'src/images/campaign/2022AAA_202211/right-sp.png';
import styles from './JulyTopBanner.module.scss';

export default function JulyTopBanner() {
  return (
    <section className={styles.section}>
      <img
        src={ImgLeft}
        alt="left"
        width={325}
        height={586}
        className={styles.img}
      />
      <Hidden xsDown>
        <img src={ImgRight} alt="right" width={497} height={407} />
      </Hidden>
      <Hidden smUp>
        <img src={ImgRightSp} alt="right" className={styles.img} />
      </Hidden>
    </section>
  );
}
