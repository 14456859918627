import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';
import Img1 from 'src/images/why-paidy-1.png';
import Img2 from 'src/images/why-paidy-2.png';
import styles from './JulyWhyPaidy.module.scss';

const DATA = [
  {
    key: 1,
    img: Img1,
    title: '分割手数料無料の3回あと払い',
    text: (
      <>
        ３回あと払いを分割手数料無料*で利用できます。ちょっとだけ手の届かない商品もかしこく計画的に購入。
        <br />
        <br />
        <small>*口座振替・銀行振込のみ無料</small>
      </>
    ),
    button: {
      text: '3回あと払いの詳細を見る',
      to: 'https://paidy.com/landing/plus_3pay/',
    },
  },
  {
    key: 2,
    img: Img2,
    title: 'ペイディカードでどこでも',
    text:
      'Visaマークのあるお店でクレジットカードとしてお買い物を楽しめます。ネットショップで使えるバーチャルカードは最短5分で無料・即日発行。さらにリアルカードを発行すると、街のお店でも使えます。',
    button: {
      text: 'ペイディカードの詳細を見る',
      to: 'https://paidy.com/landing/paidycard/',
    },
  },
];

export default function JulyWhyPaidy({ data = DATA }) {
  return (
    <section className={styles.section}>
      <h1 className={styles.title}>ペイディが選ばれる理由</h1>
      <div className={styles.container}>
        {data.map((item) => (
          <div key={item.key} className={styles.item}>
            <img
              src={item.img}
              alt={`item-${item.key}`}
              width={237}
              height={154}
              className={styles.img}
            />
            <h3 className={styles.title}>{item.title}</h3>
            <p className={styles.text}>{item.text}</p>
            <OutboundLink
              className={styles.btn}
              href={item.button.to}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.button.text}
            </OutboundLink>
          </div>
        ))}
      </div>
    </section>
  );
}
