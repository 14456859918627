import React from 'react';
import LinearCTAButton from 'src/components/LinearCTAButton';
import styles from './TwitterAfter.module.scss';

export default function TwitterAfter() {
  return (
    <section className={styles.section}>
      <h1 className={styles.title}>当選後の流れ</h1>
      <p className={styles.text}>
        応募期間終了後、ペイディ公式Twitterアカウント「＠PaidyJP」より当選通知がTwitterダイレクトメッセージで届きます。
        <br />
        Twitterダイレクトメッセージでは、電子チケット発券に必要な「シリアルコード」と「受けURL」のご案内をさせていただきます。
        <br />
        記載の受付URLに沿って、電子チケットの発券をお願いいたします。
      </p>
      <small className={styles.small}>
        ※お使いの端末のTwitterの通知設定（プッシュ通知）をオンにしてください。
        <br />
        ※投稿の非公開設定をONにされている方は、参加対象外になりますのでご注意ください。
        <br />
        ※当選者は、本権利を他人に譲渡したり、金銭と交換したりすることはできません。
      </small>
      <LinearCTAButton
        className={styles.btn}
        text="応募規約・注意事項はこちら"
        to="https://download.paidy.com/20221104_AAAxPaidyCP_terms.pdf"
        isExternal
      />
    </section>
  );
}
