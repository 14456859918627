import React from 'react';
import LinearCTAButton from 'src/components/LinearCTAButton';
import StepNumber from 'src/components/StepNumber';
import TwitterIcon from 'src/images/twitter.svg';
import styles from './Twitter.module.scss';

const DATA = [
  {
    number: 1,
    text: (
      <>
        <strong>@PaidyJP </strong>をフォロー。
      </>
    ),
  },
  {
    number: 2,
    text: (
      <>
        <span className={styles.blue}>
          #ペイディさん2022AAAで推しに会いたいです
        </span>
        をつけ引用RTで会いたい推しを教えてください。
      </>
    ),
  },
  {
    number: 3,
    text:
      '応募期間終了後、当選者のみ後日当選通知がTwitterダイレクトメッセージで届きます。',
  },
];

export default function Twitter() {
  return (
    <section className={styles.section}>
      <h1 className={styles.title}>
        <img
          alt="twitter"
          src={TwitterIcon}
          width={39}
          height={32}
          className={styles.twitter}
        />
        参加方法
      </h1>
      <div className={styles.container}>
        {DATA.map((data) => (
          <div key={data.number} className={styles.row}>
            <StepNumber>{data.number}</StepNumber>
            <p className={styles.text}>{data.text}</p>
          </div>
        ))}
      </div>
      <LinearCTAButton
        text="応募はこちら"
        to="https://twitter.com/PaidyJP"
        isExternal
      />
      <p className={styles.description}>
        ※ご自身のTwitterアカウントが非公開設定の場合、抽選の対象外となりますのでご注意ください。
        <br />
        ※Twitterダイレクトメッセージを送信する際に投稿が削除されている場合、応募を無効とさせていただきます。公式アカウントのフォローが解除されている場合も同様です。予めご了承ください。
        <br />
        ※未成年の方は応募対象外となります。
      </p>
    </section>
  );
}
