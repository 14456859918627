import React from 'react';
import { SITE_METADATA } from 'src/constants';
import Layout from 'src/components/Layout/Layout';
import JulyPayLater from 'src/components/Merchant/Campaign/2022AAA_202211/JulyPayLater';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignFooter';
import JulyCondition from 'src/components/Merchant/Campaign/2022AAA_202211/JulyConditions';
import JulyAppDownload from 'src/components/Merchant/Campaign/2022AAA_202211/JulyAppDownload';
import JulyWhyPaidy from 'src/components/Merchant/Campaign/2022AAA_202211/JulyWhyPaidy';
import JulyTopBanner from 'src/components/Merchant/Campaign/2022AAA_202211/JulyTopBanner';
import Artist from 'src/components/Merchant/Campaign/2022AAA_202211/Artist';
import Twitter from 'src/components/Merchant/Campaign/2022AAA_202211/Twitter';
import TwitterAfter from 'src/components/Merchant/Campaign/2022AAA_202211/TwitterAfter';

const SEOProps = {
  title: SITE_METADATA.twitter_2022AAA_202211.title,
  description: SITE_METADATA.twitter_2022AAA_202211.description,
  ogImage: require('src/images/campaign/2022AAA_202211/Paidy_AAA_OGP.png'),
  twitterImage: 'https://paidy-staging.com/Paidy_AAA_OGP.png',
};

export default function Twitter2022AAA202211() {
  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter>
      <JulyTopBanner />
      <Artist />
      <JulyCondition />
      <Twitter />
      <TwitterAfter />
      <JulyPayLater />
      <JulyWhyPaidy />
      <JulyAppDownload />
      <SectionCampaignFooter isThemeBlack />
    </Layout>
  );
}
