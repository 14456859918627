import React from 'react';
import styles from './Artist.module.scss';

export default function Artist() {
  return (
    <section className={styles.section}>
      <h1 className={styles.title}>ASIA ARTIST AWARDS とは</h1>
      <p className={styles.text}>
        ASIA ARTIST
        AWARDS(アジアアーティストアワード)とは、K-POP、K-Drama、K-Movieが融合した韓国初のアジアの授賞式。
        <br />
        一年を通じてアジアと韓国の音楽界で輝いたアーティストを中心に、視聴者投票、デジタルチャート、CDの販売数、専門審査委員団の審査結果を総合して受賞者を選出する「音楽部門」。
        <br />
        地上波またはケーブルテレビ等、全てのチャンネルの作品を審査する「ドラマ部門」が融合したアジア最大級のライブイベントです。
      </p>
    </section>
  );
}
