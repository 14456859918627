import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';
import SectionCampaignCondition from 'src/components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignCondition';

const DATA = [
  {
    title: <strong>キャンペーン概要</strong>,
    content: (
      <>
        あと払いペイディ公式Twitterアカウント<strong>「@PaidyJP</strong>
        （以下、公式アカウント）」をフォローし、ハッシュタグ「
        <strong>#ペイディさん2022AAAで推しに会いたいです</strong>
        」をつけ、引用RTであなたが会いたい推しを投稿してください。
        参加者の中から抽選で、「2022 Asia Artist
        Awards」チケットを50組(100名様)にプレゼントいたします。
      </>
    ),
  },
  {
    title: <strong>キャンペーン期間</strong>,
    content: '2022年11月10日（木）8:00～11月17日（木）23:59',
  },
  {
    title: <strong>プレゼント概要</strong>,
    content: (
      <>
        2022 Asia Artist Awards 入場ペアチケット
        <br />
        1セット（2名用）
      </>
    ),
  },
  {
    title: <strong>日程</strong>,
    content: '2022年12月13日（火）',
  },
  {
    title: <strong>場所</strong>,
    content: '日本ガイシホール（名古屋）',
  },
  {
    title: <strong>人数</strong>,
    content: (
      <>
        50組（100名様）
        <br />
        <br />
        <small>
          ※イベントに関する詳細情報は、「2022 Asia Artist Awards」の
          <OutboundLink
            href="https://twitter.com/aaa2022_jp"
            rel="noopener noreferrer"
            target="_blank"
          >
            公式Twitter
          </OutboundLink>
          または
          <OutboundLink
            href="https://www.asiaartistawards.jp/"
            rel="noopener noreferrer"
            target="_blank"
          >
            公式サイト
          </OutboundLink>
          をご確認ください。
        </small>
        © STARNEWS ・ MEDIABOY
      </>
    ),
  },
];

export default function JulyCondition() {
  return <SectionCampaignCondition data={DATA} />;
}
